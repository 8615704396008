import styled from "styled-components"
import { LazyLoadImage } from "react-lazy-load-image-component"

export const Box = styled.div`
    background-color: ${({ bg }) => bg ? bg : ''};
`

export const IconBoxColor = styled.div`
    svg {
        [stroke] {
            stroke: ${({ iconColor }) => iconColor ? iconColor : ''};
        }
    }
`

export const LazyImage = (props) => {
    return (
        <LazyLoadImage
            {...props}
            effect="blur"
        />
    )
}


export const HeadingStyled = styled.div`
    font-size:${props => props.size ? props.size : ''};
    font-weight:${props => props.weight ? props.weight : 'bold'};
    font-family: ${props => props.family ? props.family : "var(--bold)"};
    color:${props => props.color ? props.color : ""};
    line-height: ${props => props.lh ? props.lh : ''};
    letter-spacing: ${props => props.spacing ? props.spacing : ''};
    display: ${props => props.display ? props.display : ''};
    text-transform: ${props => props.transform ? props.transform : ''};
`
export const SpanStyled = styled.span`
    font-size:${props => props.size ? props.size : ''};
    font-weight:${props => props.weight ? props.weight : 'normal'};
    font-family: ${props => props.family ? props.family : "var(--regular)"};
    color:${props => props.color ? props.color : "var(--darkGrey)"};
    line-height: ${props => props.lh ? props.lh : ''};
    letter-spacing: ${props => props.spacing ? props.spacing : ''};
    text-transform: ${props => props.transform ? props.transform : ''};
`
export const MainHeading = styled.span`
    font-size:${props => props.size ? props.size : '39px'};
    font-family: ${props => props.family ? props.family : "var(--bold)"};
    color:${props => props.color ? props.color : "var(--darkGrey)"};
    line-height: ${props => props.lh ? props.lh : '50px'};
    letter-spacing: ${props => props.spacing ? props.spacing : ''};
    text-transform: ${props => props.transform ? props.transform : ''};
    
`
export const TextStyled = styled.p`
    font-size:${props => props.size ? props.size : '15px'};
    font-weight:${props => props.weight ? props.weight : 'normal'};
    font-family: ${props => props.family ? props.family : "var(--regular)"};
    color:${props => props.color ? props.color : "var(--darkGrey)"};
    line-height: ${props => props.lh ? props.lh : ''};
    letter-spacing: ${props => props.spacing ? props.spacing : ''};
`

export const LabelStyled = styled.label`
    font-size:${props => props.size ? props.size : ''};
    font-family: ${props => props.family ? props.family : "var(--semiBold)"};
    color:${props => props.color ? props.color : 'var(--darkGrey)'};
    line-height:${props => props.lh ? props.lh : 'normal'};
    letter-spacing: ${props => props.spacing ? props.spacing : ''};
`

export const ButtonStyled = styled.button`
    font-size:${props => props.size ? props.size : '16px'};
    font-weight:${props => props.weight ? props.weight : '500'};
    font-family:${props => props.direction === 'ltr' && props.family ? props.family : "var(--medium)"};
    background-color:${props => props.bg ? props.bg : "none"};
    color: ${props => props.color ? props.color : "var(--ThemeColor)"};
    padding: ${props => props.padding ? props.padding : "11px 20px"};
    border: ${props => props.border ? props.border : "none"};
    width: ${props => props.width ? props.width : "100%"};
    line-height: ${props => props.lineHeight ? props.lineHeight : "normal"};
    border-radius: ${props => props.radius ? props.radius : "8px"};
    transition: 0.3s ease-in-out opacity;
    letter-spacing: 0.8px;
    &:hover {
        opacity: 0.8;
    }
`

export const CardStyled = styled.div`
    box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.03);
    border-radius: 15px;
    background-color:${props => props.bg ? props.bg : "var(--white)"};
    .card-styled-header {
        padding: 16px;
        border-bottom: 1px solid var(--grey-light);
        margin-bottom: 16px;
    }
`