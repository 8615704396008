import React from 'react'
import styled from 'styled-components'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, LazyImage, MainHeading, SpanStyled, TextStyled } from '../../../ui/Elements'
import { Plane } from '../../../ui/AllImages'
import { ServicesBox } from '../../../ui/CustomElements'
import { useTranslation } from 'react-i18next'

const Expertise = () => {
    const { t } = useTranslation()
    return (
        <ExpertiseWrapper className='container-fluid common-space expertiseFluid' id='services'>
            <Container>
                <Box className='planeImg'>
                    <LazyImage src={Plane} />
                </Box>
                <Box className=''>
                    <Box className='d-flex flex-column align-items-center justify-content-center text-center my-3 main-heading journeyHeading'>
                        <MainHeading className='py-2 main-text main-heading'>{t('YourjourneyOurExpertise')} <MainHeading color='var(--themeColor)' family="var(--bold)"> {t('Unveilingourservices')}</MainHeading></MainHeading>
                    </Box>
                    <TextStyled className='journeyDesc'>
                    {t('InSkyWorld')}
                    </TextStyled>
                </Box>
                <Row className='serviceRow'>
                    <Col sm={12} md={6} lg={3}>
                        <ServicesBox className="service1" desc={t('InternationalDrivingPermits')} />
                    </Col>
                    <Col sm={12} md={6} lg={3}>
                        <ServicesBox className="service2" desc={t('Alltouristactivities')} />
                    </Col>
                    <Col sm={12} md={6} lg={3}>
                        <ServicesBox className="service3" desc={t('Alldomesticinternational')} />
                    </Col>
                    <Col sm={12} md={6} lg={3}>
                        <ServicesBox className="service4" desc={t('Airporttransfer')} />
                    </Col>
                    <Col lg={6}>
                        <ServicesBox className="service5" desc={t('Honeymoontrips')} />
                    </Col>
                    <Col lg={6}>
                        <ServicesBox className="service6" desc={t('hotelsapartments')} />
                    </Col>
                    <Col sm={12} md={6} lg={3}>
                        <ServicesBox className="service7" desc={t('Familytrips')} />
                    </Col>
                    <Col sm={12} md={6} lg={3}>
                        <ServicesBox className="service8" desc={t('Flightreservations')} />
                    </Col>
                    <Col sm={12} md={6} lg={3}>
                        <ServicesBox className="service9" desc={t('Privatecarrental')} />
                    </Col>
                    <Col sm={12} md={6} lg={3}>
                        <ServicesBox className="service10" desc={t('Travelvisas')} />
                    </Col>
                </Row>
                
            </Container>
        </ExpertiseWrapper>

    )
}
const ExpertiseWrapper = styled.section`
    
`

export default Expertise
