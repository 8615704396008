import React from 'react'
import styled from 'styled-components'
import { Container } from 'react-bootstrap'
import { Box, LazyImage, MainHeading, SpanStyled, TextStyled } from '../../../ui/Elements'
import { ArrowRight, TicketDes, TicketWorldImg } from '../../../ui/AllImages'
import { ContactButton } from '../../../ui/CustomElements'
import { useTranslation } from 'react-i18next'

const Ticket = () => {
    const { t } = useTranslation()
    return (
        <TicketWapper className='container-fluid common-space ticketFluid'>
            <Container className='position-relative'>
                <Box className='ticketWorld'>
                    <LazyImage className='ticket-world-img' src={TicketWorldImg} />
                </Box>
                <Box className='w-100 ticketContainer'>
                    <Box className='ticket-text-card'>
                        <MainHeading className='main-heading' color='var(--white)'>{t('Sahab-al-Alam')}</MainHeading>
                        <Box className='ticketDetailWrapper'>
                            <Box className='ticketDetailCol ticketFromDetailCol'>
                                <SpanStyled className='txtFromTo'>{t('From')}</SpanStyled>
                                <SpanStyled className='txtDest'>{t('KingKhalid')}</SpanStyled>
                                <SpanStyled className='txtCont'>{t('TheKingdomSaudiArabia')}</SpanStyled>
                                <SpanStyled className='txtDate'>{t('November')}</SpanStyled>
                            </Box>
                            <Box className='ticketDetailDesCol'>
                                <LazyImage src={TicketDes} />
                            </Box>
                            <Box className='ticketDetailCol ticketToDetailCol'>
                                <SpanStyled className='txtFromTo'>{t('To')}</SpanStyled>
                                <SpanStyled className='txtDest'>{t('LondonAirport')}</SpanStyled>
                                <SpanStyled className='txtCont'>{t('TheKingdomSaudiArabia')}</SpanStyled>
                                <SpanStyled className='txtDate'>{t('November2')}</SpanStyled>
                            </Box>
                        </Box>
                        <Box className='abtUserWrapper'>
                            <Box className='abtUserCol abtUserColName'>
                                <SpanStyled className='abtUserTitle'>{t('PassengerName')}</SpanStyled>
                                <SpanStyled className='abtUserTxt abtUserName'>{t('MAbdullah')}</SpanStyled>
                            </Box>
                            <Box className='abtUserCol'>
                                <SpanStyled className='abtUserTitle'>{t('Journey')}</SpanStyled>
                                <SpanStyled className='abtUserTxt'>A 0137</SpanStyled>
                            </Box>
                            <Box className='abtUserCol'>
                                <SpanStyled className='abtUserTitle'>{t('Seat')}</SpanStyled>
                                <SpanStyled className='abtUserTxt'>27F</SpanStyled>
                            </Box>
                            <Box className='abtUserCol'>
                                <SpanStyled className='abtUserTitle'>{t('Gate')}</SpanStyled>
                                <SpanStyled className='abtUserTxt'>18</SpanStyled>
                            </Box>
                            <Box className='abtUserCol'>
                                <SpanStyled className='abtUserTitle'>{t('Station')}</SpanStyled>
                                <SpanStyled className='abtUserTxt'>2A</SpanStyled>
                            </Box>
                        </Box>
                    </Box>
                    <Box className='boardingWrapper'>
                        <Box className="boardingContainer">
                            <MainHeading className='boardingHeading' color='var(--darkGrey)' size='24px'>{t('BoardingPass')}</MainHeading>
                            <SpanStyled color='var(--themeColor)' size='20px' family='var(--semiBold)' className='mt-1 getBPassDet'>{t('Getyourboarding')}</SpanStyled>
                            <ContactButton icon={ArrowRight} label={t('Clickhere')} />
                        </Box>

                    </Box>
                </Box>
            </Container>
        </TicketWapper>
    )
}
const TicketWapper = styled.section`
    

`
export default Ticket
