
import Banner from './banner/Banner'
import About from './about/About'
import Offers from './offers/Offers'
import WhyChoose from './whyChoose/WhyChoose'
import Expertise from './expertise/Expertise'
import Contact from './contact/Contact'
import Ticket from './ticket/Ticket'

const Index = () => {
    return (
        <>
            <Banner />
            <About />
            
            <Offers />
            
            <Expertise />
            <Contact />
        </>
    )
}

export default Index
