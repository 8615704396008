import React, { Component } from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import { Container } from 'react-bootstrap'
import { bannerDataConfig } from '../../projectData/Data'
import { ArrowRight } from '../../../ui/AllImages'
import { ContactButton } from '../../../ui/CustomElements'
import { Box, HeadingStyled, SpanStyled, TextStyled } from '../../../ui/Elements'


export default class asNavFor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }

    render() {
        const SliderSetting = {
            //dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            rtl: true,
            //ltr: true,
            currentSlide: 0
        };
        const SliderSettingTh = {
            dots: true,
            infinite: true,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 3000,
            slidesToShow: 1,
            slidesToScroll: 1,
            rtl: true,
            //ltr: true,
            currentSlide: 0
        };
        return (
            <>
                <BannerWapper className='container-fluid banner-bg' id='home'>
                    <Container>
                        <Box className='banner-wapper-card'>


{/*                             
                                <Slider {...SliderSetting}
                                    asNavFor={this.state.nav2}
                                    ref={slider => (this.slider1 = slider)}
                                    >
                                    <div>
                                        <h3>1</h3>
                                    </div>
                                    <div>
                                        <h3>2</h3>
                                    </div>
                                    <div>
                                        <h3>3</h3>
                                    </div>
                                    <div>
                                        <h3>4</h3>
                                    </div>
                                    <div>
                                        <h3>5</h3>
                                    </div>
                                    <div>
                                        <h3>6</h3>
                                    </div>
                                </Slider>
                                <h4>Second Slider</h4>
                                <Slider {...SliderSettingTh}
                                    asNavFor={this.state.nav1}
                                    ref={slider => (this.slider2 = slider)}
                                    slidesToShow={3}
                                    swipeToSlide={true}
                                    focusOnSelect={true}
                                    >
                                    <div>
                                        <h3>1</h3>
                                    </div>
                                    <div>
                                        <h3>2</h3>
                                    </div>
                                    <div>
                                        <h3>3</h3>
                                    </div>
                                    <div>
                                        <h3>4</h3>
                                    </div>
                                    <div>
                                        <h3>5</h3>
                                    </div>
                                    <div>
                                        <h3>6</h3>
                                    </div>
                                </Slider>
                                 */}
                        </Box>
                    </Container>
                </BannerWapper >
            </>
        );
    }
}
const BannerWapper = styled.section`
    .banner-wapper-card{
        // justify - content: center;
        // display: flex;
        // flex-direction: column;
        height: 100%;
        padding-top: 75px;
        // direction: ltr !important;
    }
    .banner-card{
        padding - top:70px;
        display: flex !important;
        align-items: center;
        justify-content: space-between;
    }
    .banner-text-card{
        display: flex;
        flex-direction: column;
        gap:20px;
        width: 56%;
    }

                `

//export default Banner
