export const NavDataConfig = [
    {
        to: 'home',
        label: 'Home',
    },
    {
        to: 'about',
        label: 'About',
    },
    {
        to: 'offers',
        label: 'Offers',
    },
    {
        to: 'services',
        label: 'Services',
    },
    {
        to: 'contact',
        label: 'Contact',
    },
]