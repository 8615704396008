import React from 'react'
import styled from 'styled-components'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, MainHeading, SpanStyled, TextStyled } from '../../../ui/Elements'
import { useTranslation } from 'react-i18next'
import { Video } from '../../../ui/AllImages'

const WhyChoose = () => {
    const { t } = useTranslation()
    return (
        <WhyChooseWrapper className='container-fluid common-space whyChooseFluid'>
            <Container>
                <Box className='whyChooseWrapper'>
                    <video autoPlay muted loop>
                        <source src={Video} type="video/webm" />
                    </video>
                    <Box className='d-flex flex-column align-items-center justify-content-center text-center my-3 main-heading'>
                        <MainHeading color="var(--white)" className='py-2'>{t('Whychoose')}</MainHeading>
                    </Box>
                    <Row className='whyChooseRow'>
                        <Col lg={3} md={6} sm={12}>
                            <Box className='whyChooseTitle'>
                            {t('Trip')}
                            </Box>
                            <Box className='whyChooseText'>
                                +112,000
                            </Box>
                        </Col>
                        <Col lg={3} md={6} sm={12}>
                            <Box className='whyChooseTitle'>
                            {t('Destination')}
                            </Box>
                            <Box className='whyChooseText'>
                                +40
                            </Box>
                        </Col>
                        <Col lg={3} md={6} sm={12}>
                            <Box className='whyChooseTitle'>
                            {t('YearsExperience')}
                            </Box>
                            <Box className='whyChooseText'>
                                +5
                            </Box>
                        </Col>
                        <Col lg={3} md={6} sm={12}>
                            <Box className='whyChooseTitle'>
                            {t('SatisfiedCustomer')}
                            </Box>
                            <Box className='whyChooseText'>
                                +15,000
                            </Box>
                        </Col>
                    </Row>
                </Box>
                
                
            </Container>
        </WhyChooseWrapper>

    )
}
const WhyChooseWrapper = styled.section`
    .whyChooseWrapper{
        position: relative;
        overflow: hidden;
        video {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            min-width: 100%;
            min-height: 100%;
            z-index: 1;
        }
    }
    
`

export default WhyChoose
