import React from 'react'
import styled from 'styled-components'


const Map = () => {
    const mapLocation = {
        map1: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d619158.383227242!2d46.34049393142333!3d24.734204865733442!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f03890d489399%3A0xba974d1c98e79fd5!2sRiyadh!5e0!3m2!1sen!2ssa!4v1700553663049!5m2!1sen!2ssa",
    }
    return (
        <MapWapper className='mapWrapper'>
            <iframe src={mapLocation.map1} height='100%' border='0' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </MapWapper>
    )
}
const MapWapper = styled.div`
    height: 100%;
    padding: 73px 0 96px 0;
    iframe{
        border-radius: 20px;
        width: 100%;
        height: 100%;
        border: 0;
        
    }
`

export default Map
